@tailwind components;
@tailwind utilities;

body,
html {
	font-size: 1rem;
	background-color: $bg;
	overscroll-behavior-y: none;
	overscroll-behavior-x: none;
	-webkit-text-size-adjust: none;
	-ms-text-size-adjust: none;
	-moz-text-size-adjust: none;
	text-size-adjust: none;
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	overflow-x: hidden;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: $body-font-size;
}
body {
	position: relative;
}
a {
	text-decoration: none;
	color: $brand_care;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 600;
}
input {
	font-family: sans-serif;
}
button {
	background-color: transparent;
	border: none;
	outline: none;
}
* {
	outline: none;
}

* {
	outline: none;
}
body {
	#root {
		width: 100%;
		height: 100%;
	}
}
html {
	height: 100%;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
