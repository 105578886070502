.-cdp-home-hero {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-size: cover;
	background-position: center;
}

@media screen and (max-width: 600px) {
	.-cdp-home-hero {
		min-height: 280px;
		max-height: 280px;
	}
}

@media screen and (min-width: 601px) {
	.-cdp-home-hero {
		background-position: 50% calc(50%);
		min-height: 480px;
		max-height: 480px;
	}
}

@media screen and (min-width: 1050px) {
	.-cdp-home-hero {
		background-position: 50% calc(50% + 110px);
		min-height: 480px;
		max-height: 480px;
	}
}
