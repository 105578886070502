.-cdp-ce-ui-snackbar {
	.MuiButtonBase-root {
		position: relative;
		top: -2px;
		.MuiSvgIcon-root {
			color: inherit;
			opacity: 0.7;
		}
	}
}
