.-cdp-input-form-control {
	.-cdp-input-label {
		font-weight: 600;
		font-size: 14px;
	}

	input[type='text'],
	input[type='password'] {
		max-height: $input-height !important;
		padding-top: $theme-spacing;
		padding-bottom: $theme-spacing;
	}

	.-cdp-ce-ui-country {
		.MuiInputBase-root {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			margin-top: 5px;
		}
		.MuiAutocomplete-popupIndicator {
			margin-right: 5px !important;
		}
	}

	.-cdp-ce-ui-places {
		.MuiInputBase-root {
			padding-top: 0 !important;
			padding-right: 0 !important;
			padding-bottom: 0 !important;
			margin-top: 5px;
		}
		.MuiAutocomplete-popupIndicator {
			margin-right: 5px !important;
			transform: none !important;
		}
		.MuiAutocomplete-popupIndicatorOpen {
			margin-right: 5px !important;
			transform: none !important;
		}
	}

	.-cdp-ce-phone-input {
		.MuiInputBase-root {
			padding-left: 4px;
			.MuiTelInput-Flag {
				img {
					border-radius: 2px;
				}
			}
		}
	}

	.-cdp-ce-otp-input {
		input::placeholder {
			font-size: 24px;
			transform: translateY(8px);
			color: $divider !important;
		}
	}

	.-cdp-ce-ui-places-search {
		input::placeholder {
			color: $brand_care !important;
		}
	}

	.-cdp-ce-select-input {
		&.-error-placeholder {
			.MuiSelect-select {
				color: lighten($danger, 50%) !important;
			}
		}
		&.-active-placeholder {
			.MuiSelect-select {
				color: $divider !important;
			}
		}
		.MuiSelect-icon {
			top: 12px !important;
			right: 8px !important;
		}
	}

	&.-input-error {
		input::placeholder,
		.Mui-error::placeholder,
		.-cdp-ce-checkbox-input,
		.MuiFormControlLabel-label,
		&.Mui-error input::placeholder {
			color: $danger !important;
			fill: $danger !important;
		}

		.-cdp-ce-checkbox-input {
			color: $danger;
		}
	}
}
