$theme-spacing: 8px;
$header-height: 64px;
$drawer-width: 360px;
$body-font-size: 15px;
$drawer-width: 320px;
$border-radius: 5px;
$border-width: 1px;
$input-height: 40px;
$button-height: 40px;

$xs: 350;
$sm: 500;
$md: 900;
$lg: 1200;
$xl: 1536;
